/*------------------------------------------------------------------
Table of content
-------------------
1. Start Header Area
2. slider area start
3. features area css start
4. testimonial
5. blog
6. about us
7. contact us
8. banner
9. sidebar
10. footer
-------------------------------------------------------------------*/