/*------- header top style start -------*/
.header-top {
    padding: 12px 0;
}

.header-top-left {
    color: $white;
    font-size: 18px;
    font-weight: 500;

    // responsive
    @media #{$md-device, $sm-device} {
        font-size: 16px;
    }

    a {
        color: $white;

        &:hover {
            color: $black-soft;
        }
    }
}

// header top middile
.login-register {

    // responsive
    @media #{$xs-device} {
        padding-bottom: 6px;
    }

    a {
        color: $white;
        font-size: 18px;
        font-weight: 500;
        line-height: 1;

        &:hover {
            color: $black-soft;
        }
    }
}

// header social link
.header-social-link {
    a {
        color: $white;
        margin-left: 26px;

        &:hover {
            color: $black-soft;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

/*------- header top style end -------*/

/*------ main menu start ------*/
.brand-logo {
    max-width: 110px;
}

.main-menu-wrapper {
    padding: 20px 0;

    &.header-transparent {
        padding: 43px 0;
    }
}

.main-menu {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        li {
            position: relative;

            a {
                color: $black-soft;
                font-size: 18px;
                padding: 10px 22px;
                font-weight: 500;
                display: block;
                font-family: $heading-font;

                i {
                    font-size: 14px;
                    padding: 0 3px;
                }
            }

            &:last-child {
                a {
                    padding-right: 0;
                }
            }

            &:hover>,
            &.active> {
                a {
                    color: $theme-color;
                }
            }

            ul {

                // dropdown menu
                &.dropdown {
                    top: 100%;
                    left: 0;
                    width: 175px;
                    position: absolute;
                    transition: 0.4s;
                    background-color: $white;
                    opacity: 0;
                    visibility: hidden;
                    z-index: 99999;
                    pointer-events: none;
                    transform: translateY(20px);
                    border: 1px solid #efefef;

                    li {
                        margin-right: 0;
                        border-right: none;
                        flex-basis: 100%;

                        a {
                            color: $paragraph;
                            font-size: 14px;
                            font-weight: 400;
                            padding: 10px 20px;
                            text-transform: capitalize;
                            position: relative;
                            border-bottom: 1px solid #efefef;
                            z-index: 1;

                            i {
                                float: right;
                                padding-top: 5px;
                            }
                        }

                        &:hover> {
                            a {
                                color: $white;
                                background-color: $theme-color;
                            }

                            ul {
                                &.dropdown {
                                    top: 0;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }

                        &:last-child {
                            a {
                                border-bottom: 0;
                            }
                        }

                        ul {
                            &.dropdown {
                                top: 100%;
                                left: 100%;
                                opacity: 0;
                                visibility: hidden;
                            }
                        }
                    }
                }
            }

            &:hover {
                ul {
                    &.dropdown {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: visible;
                        transform: translate(0);
                    }
                }
            }
        }
    }
}

/*------ main menu end ------*/

/*----- language style strat -----*/
.language {
    position: relative;
    color: $white;
    .dropdown-list {
        top: 100%;
        right: 0;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transform: translateY(30px);
        transition: 0.4s;
        padding: 15px 15px 20px;
        width: 150px;
        z-index: 9;
        background-color: $white;
        pointer-events: none;
        border: 1px solid #efefef;

        li {
            margin-left: 0;
            padding: 0;

            a {
                color: $paragraph;
                font-size: 14px;
                display: block;
                padding: 8px 0 3px;

                &:hover {
                    color: $theme-color;
                }
                img {
                    vertical-align: inherit;
                    padding-right: 5px;
                }
            }
        }
    }

    &:hover {
        .dropdown-list {
            opacity: 1;
            visibility: visible;
            pointer-events: visible;
            transform: translateY(0);
        }
    }
}
.mobile-top-dropdown {
    padding: 20px;
    >a {
        color: $white;
    }
    .dropdown-toggle {
        &:after {
            display: none;
        }
    }
}
/*----- language style end -----*/

/*------ sticky menu style start ------*/
.sticky {
    &.is-sticky {
        top: 0;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 9;
        background-color: $white;
        box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
        animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;

        &.main-menu-wrapper {
            padding: 10px 0;

            .brand-logo {
                max-width: 100px;
            }
        }
    }

}

/*------ sticky menu style end ------*/

// header transparent css
.header-transparent {
    top: 54px;
    left: 0;
    width: 100%;
    position: absolute;
    z-index: 1;
    background-color: transparent;
}