/*----- testimonial area start -----*/
.testimonial-wrapper {

    // responsive
    @media #{$md-device, $sm-device} {
        padding-bottom: 100px;
    }

    &--style_2 {

        // responsive
        @media #{$md-device, $sm-device} {
            padding-bottom: 68px;
        }
    }
}

.testimonial-inner {
    margin-top: -38px;

    // responsive
    @media #{$md-device, $sm-device} {
        margin-top: 68px;
    }
}

.testimonial-content {
    padding: 1px;

    .client-name {
        color: #1c47c1;
        line-height: 1;
        font-weight: 500;
        padding-bottom: 4px;
    }

    .client-desig {
        font-weight: 300;
    }

    p {
        padding-top: 57px;
        padding-bottom: 12px;

        // responsive
        @media #{$sm-device} {
            padding-top: 45px;
        }

        i {
            font-size: 14px;
            line-height: 1;
            padding-right: 5px;

            &:last-child {
                padding-left: 5px;
            }
        }
    }
}

.testimonial-thumb {

    // responsive
    @media #{$md-device} {
        text-align: center;
    }

    &--style_2 {
        @media #{$md-device, $sm-device} {
            margin-bottom: 68px;
        }
    }
}

.testimonial-item {
    &--style_2 {

        .testimonial-content {
            padding: 40px 20px 48px 45px;
            box-shadow: 0px 0px 31.5px 3.5px rgba(28, 71, 193, 0.11);

            p {
                padding-top: 0;
            }
        }
    }
}

/*----- testimonial area end -----*/