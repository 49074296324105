/*------- common css start -------*/
.fix {
    overflow: hidden;
}

// section title start
.section-title {
    max-width: 410px;
    margin: auto;
    margin-bottom: 68px;

    // responsive
    @media #{$md-device, $sm-device} {
        margin-bottom: 45px;
    }

    .title {
        padding-bottom: 15px;
    }

    p {
        padding: 0 15px;
    }

    &--style_2 {
        max-width: 710px;

        .title {
            line-height: 1.4;
            margin-top: -15px;

            @media #{$sm-device} {
                margin-top: -10px;
            }
        }
    }
}

.title {
    font-weight: 800;
    line-height: 1.1;
    margin-top: -9px;

    @media #{$sm-device} {
        margin-top: -7px;
        line-height: 1.3;
    }

    span {
        color: #1c47c1;
    }
}

.subtitle {
    font-weight: 400;
    line-height: 1.3;
    padding: 20px 0;
}


.theme-bg {
    background-color: $theme-color;
}

.gray-bg {
    background-color: $bg-gray;
}

/*------- common css end -------*/

/*---- choose us style strat ----*/
.choose-item-wrapper {
    .title {
        padding-bottom: 8px;
    }

    &--style_2 {
        background-color: $white;
        box-shadow: 0px 0px 58.5px 6.5px rgba(28, 71, 193, 0.15);
        padding: 83px 60px;

        // responsive
        @media #{$lg-device}{
            padding: 30px;
        }
        @media #{$md-device, $sm-device} {
            padding: 65px 30px 53px;
            margin-bottom: 62px;
        }

        .desc {
            padding: 25px 0 28px;
        }
    }
}

.choose-item {
    margin-top: 35px;
    // responsive
    @media #{$sm-device}{
        margin-top: 25px;
    }

    &-title {
        font-weight: 700;
        padding-bottom: 8px;
    }
}

.choose-list {
    li {
        margin-bottom: 14px;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            width: 21px;
            height: 18px;
            left: 0;
            top: 50%;
            content: '';
            position: absolute;
            transform: translateY(-50%);
            background-image: url(../img/icon/check-tick.png);
        }

        .choose-item-title {
            padding-left: 36px;
        }
    }
}

/*---- choose us style end ----*/

/*---- call to action start ----*/
.cta-wrapper {
    position: relative;
    // responsive
    @media #{$md-device, $sm-device}{
        margin-top: 55px;
        padding-bottom: 6px;
    }

    .title {
        line-height: 1.3;
    }

    .call {
        color: $theme-color;
        display: inline-block;
        font-size: 36px;
        font-weight: 800;
        padding-top: 23px;

        // responsive
        @media #{$sm-device} {
            font-size: 28px;
        }
        @media #{$xxs-device} {
            font-size: 24px;
        }

        &:hover {
            color: $black-soft;
        }
    }

    img {
        // padding-left: 40px;

        // responsive
        @media #{$sm-device} {
            padding-left: 0;
        }

    }
}

/*---- call to action end ----*/

/*---- brand logo section start ----*/
.brand-item {
    img {
        margin: auto;
        opacity: 0.25;
    }

    &:hover {
        img {
            opacity: 1;
        }
    }
}

/*---- brand logo section end ----*/

/*------ counter up start ------*/
.counterup-item {
    text-align: center;

    span {
        color: $theme-color-2;
        font-weight: 800;
        margin-top: -4px;
    }

    h5 {
        font-weight: 400;
        padding-top: 5px;

        // responsive
        @media #{$md-device, $sm-device} {
            font-size: 16px;
        }
    }
}

.odometer-inside {
    position: relative;
    margin-left: -22px;

    &::after {
        top: -7px;
        left: 100%;
        content: '+';
        position: absolute;

    }
}

/*------ counter up end ------*/

/*----- slick arrow style start -----*/
.testimonial-carousel-active,
.hero-slider-active {
    &.slick-arrow-style {
        button {
            &.slick-arrow {
                width: 16px;
                height: 18px;
                bottom: -38px;
                left: 0;
                cursor: pointer;
                position: absolute;
                background-repeat: no-repeat;

                &.slick-prev {
                    background-image: url(../img/icon/arrow-prev.png);
                }

                &.slick-next {
                    left: 25px;
                    background-image: url(../img/icon/arrow-next.png);
                }

                &:hover {
                    &.slick-prev {
                        background-image: url(../img/icon/arrow-prev-color.png);
                    }

                    &.slick-next {
                        background-image: url(../img/icon/arrow-next-color.png);
                    }
                }
            }
        }

        &--testimonial_2 {
            margin-top: 35px;

            button {
                &.slick-arrow {
                    right: 0;
                    left: auto;
                    bottom: 60px;
                    z-index: 1;

                    &.slick-prev {
                        right: 60px;
                    }

                    &.slick-next {
                        right: 37px;
                        left: auto;
                    }
                }
            }

            .slick-list {
                margin: -30px;

                // responsive
                @media #{$md-device, $sm-device} {
                    margin: -30px -15px;
                }

                .slick-slide {
                    padding: 30px;
                }
            }
        }
    }
}

.slick-arrow-style {
    &_hero {
        button {
            &.slick-arrow {
                bottom: 40px;
                left: 50%;
                transform: translateX(-50%);
                margin: auto;

                &.slick-next {
                    right: 48%;
                    left: auto;
                }

                &.slick-prev {
                    left: 49%;
                }
            }
        }
    }
}

/*----- slick arrow style end -----*/

/* ----scroll to top css start ----*/
.scroll-top {
    bottom: 50px;
    cursor: pointer;
    height: 50px;
    position: fixed;
    right: 20px;
    text-align: center;
    width: 50px;
    z-index: 9999;
    transition: 0.4s;
    border-radius: 50%;
    background-color: $theme-color;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);

    i {
        line-height: 50px;
        color: #fff;
        font-size: 25px;
    }

    &.not-visible {
        bottom: -50px;
        visibility: hidden;
        opacity: 0;
    }

    &:hover {
        background-color: $black-soft;
    }
}

/* ----scroll to top css end ----*/

// vertical up css
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 40%, 0)
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0)
    }
}

.fadeInUp {
    animation-name: fadeInUp
}

// fade in left css
@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-40%, 0, 0)
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0)
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}

// fade in right
@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(40%, 0, 0)
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0)
    }
}

.fadeInRight {
    animation-name: fadeInRight
}

// moving vertical
@keyframes float-bob {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0);
    }
}

.moving-vertical {
    animation-name: float-bob;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}