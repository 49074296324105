/*----- about style start -----*/
.about {
    &-inner {

        // responsive
        @media #{$md-device, $sm-device} {
            margin-top: 65px;
        }

        p {
            margin-bottom: 34px;
        }

        &--style_2 {

            // responsive
            @media #{$md-device, $sm-device} {
                margin-top: 0;
                margin-bottom: 65px;
            }

            .subtitle {
                padding-top: 24px;

                // responsive
                @media #{$md-device, $sm-device} {
                    padding-bottom: 5px;
                }

                span {
                    color: $theme-color-2;
                    font-weight: 800;
                }

                &-2 {
                    padding-top: 3px;
                    padding-bottom: 46px;
                }
            }
        }
    }

    &-thumb {

        // responsive
        @media #{$md-device, $sm-device} {
            text-align: center;
        }
    }
}

/*----- about style end -----*/