.error {
    h1 {
        font-size: 200px;
        font-weight: 900;
        letter-spacing: 1rem;
        line-height: 1;
        margin-top: -44px;
        color: $theme-color;

        // responsive
        @media #{$md-device} {
            font-size: 130px;
            margin-top: -30px;
        }

        @media #{$sm-device} {
            font-size: 100px;
            margin-top: -23px;
        }
    }

    h2 {
        font-size: 25px;
        padding: 10px 0;

        // responsive
        @media #{$xxs-device} {
            font-size: 20px;
        }
    }

    .searchform {
        display: flex;
        margin-top: 40px;
        justify-content: flex-start;

        &__input {
            border: 1px solid $bdr-color;
            height: 45px;
            width: 100%;
            padding-left: 15px;

            // responsive
            @media #{$xxs-device} {
                width: 85%;
            }
        }

        &__submit {
            border: none;
            background-color: $theme-color;
            width: 70px;
            color: $white;
            cursor: pointer;

            &:hover {
                background-color: $black-soft;
            }
        }
    }

    .btn-all {
        margin-top: 30px;
    }
}