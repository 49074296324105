/*----- Google map area start -----*/
#google-map {
    height: 500px;
    width: 100%;

    // responsive
    @media #{$md-device} {
        height: 400px;
    }

    @media #{$sm-device} {
        height: 350px;
    }
}

/*----- Google map area end -----*/

/*------ contact form area start -------*/
.contact-message {
    form {

        input,
        textarea {
            width: 100%;
            border: none;
            padding: 10px 10px;
            border-bottom: 3px solid transparent;
            background-color: #f7f7f7;
            margin-bottom: 30px;
        }

        textarea {
            height: 150px;
        }
    }
}

/*------ contact form area start -------*/

/*------ contact info area start -------*/
.contact-information {
    height: 100%;
    padding-left: 30px;

    // responsive
    @media #{$md-device, $sm-device} {
        margin-bottom: 65px;
        padding-left: 0;
    }

    p {
        padding-bottom: 30px;

        // responsive
        @media #{$md-device, $sm-device} {
            padding-bottom: 10px;
        }
    }

    ul {
        li {
            border-bottom: 1px solid $bdr-color;
            padding-bottom: 15px;
            margin-bottom: 15px;
            display: flex;
            align-items: center;

            // responsive
            @media #{$xxs-device} {
                align-items: baseline;
            }

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            i {
                font-size: 18px;
                padding-right: 10px;
            }
        }
    }
}

.contact-title {
    padding-bottom: 35px;

    // responsive
    @media #{$md-device, $sm-device} {
        margin-top: -13px;
        padding-bottom: 20px;
    }
}

/*------ contact info area end -------*/