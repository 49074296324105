/*------ hero slider area css start ------*/
/*-------------------------
01. Slider area
--------------------------*/
.hero-slider-item {
    height: 850px;
    display: flex;
    align-items: center;
    padding-top: 88px;

    // responsive
    @media #{$lg-device} {
        height: 550px;
    }

    @media #{$md-device} {
        height: 400px;
        padding-top: 0;
    }

    @media #{$sm-device} {
        height: 350px;
        padding-top: 0;
    }

    @media #{$xs-device} {
        height: 550px;
    }
}

.slider-thumb {
    float: right;

    @media #{$xs-device} {
        float: none;
        max-width: 300px;
        margin: 0 auto;
    }
}

.hero-slider-content {

    // responsive

    @media #{$xs-device} {
        text-align: center;
        padding: 0 20px 50px;
    }

    @media #{$xxs-device} {
        padding: 0 0 50px;
    }

    .slide {
        &-title {
            color: $black-soft;
            font-size: 75px;
            font-weight: 800;
            line-height: 1;

            // responsive
            @media #{$lg-device} {
                font-size: 40px;
            }

            @media #{$md-device} {
                font-size: 34px;
                line-height: 1.3;
            }

            @media #{$sm-device} {
                font-size: 25px;
                line-height: 1.3;
            }
        }
    }

    .btn-hero {
        margin-top: 55px;

        @media #{$sm-device} {
            margin-top: 30px;
            padding: 10px 22px 12px;
            font-size: 18px;
        }
    }
}

// 02. slider 02 start
.hero-transparent-bg {
    height: 890px;

    // responsive
    @media #{$lg-device} {
        height: 550px;
    }

    @media #{$md-device} {
        height: 400px;
        padding-top: 0;
    }

    @media #{$sm-device} {
        height: 350px;
        padding-top: 0;
    }

    @media #{$xs-device} {
        height: 550px;
        padding-top: 0;
    }
}


/*------ hero slider area css end ------*/

// slider content animation
.slick-active {
    .slider-thumb {
        img {
            animation-fill-mode: both;
            animation-duration: 1s;
            animation-name: fadeInRight;
            animation-delay: 0.5s;
        }
    }

    .hero-slider-content {

        .slide-title,
        .btn-hero {
            animation-fill-mode: both;
            animation-duration: 1s;
            animation-name: fadeInLeft;
            animation-delay: 0.5s;
        }

        .btn-hero {
            animation-delay: 0.7s;
        }
    }
}