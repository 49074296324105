/*----- mobile menu start -----*/
.mobile-header {
    padding: 15px 0;
}

.mobile-logo {
    max-width: 100px;
}

.mobile-main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mobile-menu-toggler {
        display: flex;
        align-items: center;

        .mobile-menu-btn {
            margin-left: 25px;

            span {
                width: 25px;
                height: 2px;
                display: block;
                transition: 0.4s;
                background-color: $black-soft;
                transform-origin: left;

                &:nth-child(2),
                &:nth-child(3) {
                    margin-top: 5px;
                }
            }

            &:hover {
                span {
                    background-color: $theme-color;

                    &:nth-child(1),
                    &:nth-child(3) {
                        width: 20px;
                    }
                }
            }
        }
    }
}

.mobile-navigation {
    overflow: hidden;
    max-height: 360px;
    padding: 20px;

    nav {
        height: 100%;
    }
}

.mobile-menu {
    margin-top: 30px;
    height: 100%;
    overflow: auto;
    padding-right: 30px;
    margin-right: -30px;

    // responsive
    @media #{$xxs-device} {
        margin-top: 15px;
    }

    & li {
        &>a {
            font-size: 16px;
            color: $white;
            text-transform: capitalize;
            line-height: 18px;
            position: relative;
            display: inline-block;
            padding: 10px 0;

            &:hover {
                color: $black-soft;
            }
        }

        & ul {
            & li {
                border: none;

                & a {
                    font-size: 14px;
                    text-transform: capitalize;
                    padding: 10px 0 8px;
                }
            }
        }

        &.menu-item-has-children {
            display: block;
            position: relative;

            .dropdown {
                padding-left: 15px;
            }

            .menu-expand {
                line-height: 50;
                top: -5px;
                left: 95%;
                width: 30px;
                position: absolute;
                height: 50px;
                text-align: center;
                cursor: pointer;

                i {
                    display: block;
                    position: relative;
                    width: 10px;
                    margin-top: 25px;
                    border-bottom: 1px solid $white;
                    transition: all 250ms ease-out;

                    &:before {
                        top: 0;
                        width: 100%;
                        content: "";
                        display: block;
                        position: absolute;
                        transform: rotate(90deg);
                        border-bottom: 1px solid $white;
                        transition: 0.4s;
                    }
                }
            }

            &.active> {
                .menu-expand {
                    i {
                        &:before {
                            transform: rotate(0);
                        }
                    }
                }
            }
        }
    }
}



/*----- mobile menu end -----*/

/*------ offcanvas widget area start ------*/
.offcanvas-widget-area {
    margin-top: auto;
    padding: 20px;
}

.off-canvas-contact-widget {
    li {
        color: $white;
        font-size: 15px;
        margin-bottom: 5px;

        i {
            width: 20px;
        }

        a {
            color: $white;

            &:hover {
                color: $black-soft;
            }
        }
    }
}

.off-canvas-social-widget {
    margin-top: 20px;

    a {
        color: $white;
        font-size: 18px;
        display: inline-block;
        margin-right: 15px;

        &:hover {
            color: $black-soft;
        }
    }
}

/*------ offcanvas widget area end ------*/
.off-canvas-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    z-index: 9999;

    &.open {
        opacity: 1;
        visibility: visible;

        .off-canvas-inner-content {
            transform: none;
        }
    }

    .off-canvas-overlay {
        background-color: rgba($black, 0.4);
        content: '';
        cursor: url('../img/icon/cancel.png'), auto;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .off-canvas-inner-content {
        background-color: $theme-color;
        width: 300px;
        height: 100%;
        position: relative;
        transform: translateX(calc(-100% - 50px));
        transition: 0.4s;

        // responsive
        @media #{$xxs-device} {
            width: 270px;
        }
    }

    .btn-close-off-canvas {
        top: 0;
        left: 100%;
        width: 60px;
        height: 60px;
        font-size: 26px;
        color: $theme-color;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        position: absolute;
        transition: 0.4s;
        background-color: $white;

        i {
            transform: rotate(0);
            transition: 0.4s;
            display: block;
            line-height: 60px;
        }

        &:hover {
            i {
                transform: rotate(-90deg);
            }
        }
    }

    .off-canvas-inner {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        height: 100%;
    }
}


.search-box-offcanvas {
    form {
        position: relative;

        input {
            color: #666;
            font-size: 13px;
            width: 100%;
            height: 60px;
            border: none;
            padding: 0 40px 0 10px;
            background-color: #f2f2f2;
        }

        .search-btn {
            top: 0;
            right: 0;
            width: 40px;
            height: 60px;
            line-height: 62px;
            font-size: 20px;
            color: $theme-color;
            position: absolute;

            &:hover {
                color: $theme-color;
            }
        }
    }
}

/*------- mobile top bar settings start -------*/
.mobile-settings {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid $bdr-color;

    li {
        margin-bottom: 5px;
    }

    .nav {
        flex-direction: column;

        .mobile-top-dropdown {
            .dropdown-toggle {
                font-size: 14px;
                color: $paragraph;

                i {
                    font-size: 12px;
                    padding-left: 5px;
                    vertical-align: middle;
                }

                &:hover {
                    color: $theme-color;
                }

                cursor: pointer;

                &:after {
                    display: none;
                }
            }

            .dropdown-menu {
                padding: 0;
                border-color: $bdr-color;
                transform: translateY(30px);
                transition: 0.4s;

                &.show {
                    transform: translateY(0);
                }

                .dropdown-item {
                    font-size: 13px;
                    color: $paragraph;
                    padding: 6px 15px;
                }
            }
        }
    }
}

/*------- mobile top bar settings end -------*/